$placeholder-color: $grey-4;

//////////////////////////////////////
//
//  GLOBALS
//
//////////////////////////////////////

.gform_fields {
	@extend .form-fields;
}

.form-fields,
.gform_fields {
	padding-left: 0 !important;
	li, > .gfield {
		margin-bottom: $base-spacing-unit * 1.5;
	}
}

label,
.label,
.gpoll_field_label {
	@include primary-font(bold);
	margin-bottom: 4px;
}


input {
	border-radius: 0;
	@include primary-font;
}


.gfield {
	clear: both;
	img {
		width: auto;
		height: auto;
	}
}
.ginput_full,
.ginput_card_expiration_container {
	@extend .cf;
	margin-bottom: 4px;
	display: block;
	width: 100%;
	input {
		width: 100%;
	}
}
.ginput_left,
.ginput_cardinfo_left,
.ginput_card_expiration_container .styled-select:first-of-type {
	margin-bottom: 4px;
	display: inline-block;
	width: 50%;
	padding-right: 10px;
	float: left;
	input {
		width: 100%;
	}
}
.ginput_right,
.ginput_cardinfo_right,
.ginput_card_expiration_container .styled-select:last-of-type {
	margin-bottom: 4px;
	display: inline-block;
	width: 50%;
	padding-left: 10px;
	float: right;
	clear: none;
	input {
		width: 100%;
	}
}

.gfield_required {
	padding-left: 4px;
}


.gform_card_icon_container {
	margin-bottom: 6px;
}

.ginput_card_security_code_icon,
.gform_card_icon {
	@extend .sprite;
	background-image: url(/wp-content/plugins/gravityforms/images/gf-creditcard-icons.png);
	background-repeat: no-repeat;
	width: 32px;
	height: 24px;
}

.ginput_card_security_code {
	margin-bottom: 6px;
}
.ginput_card_security_code_icon {
	background-position: 0 -128px;
	+ label {
		display: inline-block;
		margin-left: 10px;
	}
}

.gform_card_icon {
	margin-left: 6px;

	&:first-child {
		margin-left: 0;
	}

	&.gform_card_icon_visa {
		background-position: -2px -4px;
	}
	&.gform_card_icon_mastercard {
		background-position: -38px -4px;
	}
	&.gform_card_icon_amex {
		background-position: -74px -4px;
	}
}

//////////////////////////////////////
//
//  PLACEHOLDERS
//
//////////////////////////////////////

::-webkit-input-placeholder {
	color: $placeholder-color;
}
::-moz-placeholder {
	color: $placeholder-color;
}
:-ms-input-placeholder {
	color: $placeholder-color;
}
input:-moz-placeholder {
	color: $placeholder-color;
}


//////////////////////////////////////
//
//  TEXT BASED INPUTS
//
//////////////////////////////////////

input[type="text"],
input[type="password"],
input.text-input,
textarea.text-input,
textarea,
.text-input input,
.text-input textarea,
.gform_fields input[type="text"],
.gform_fields input[type="password"],
.gform_fields input[type="name"],
.gform_fields input[type="email"],
.gform_fields input[type="url"],
.gform_fields input[type="tel"],
.gform_fields input[type="color"],
.gform_fields input[type="number"] {
	display: block;
	max-width: 100%;
	width: 100%;
	background: $white;
	border: 1px solid $grey-4;
	color: $base-ui-color;
	padding: $base-spacing-unit / 2;
	font-size: $base-font-size;
	border-radius: $brand-round;

	&.placeholder {
		color: $placeholder-color;
	}
	&:focus {
		outline: none;
	}
}

textarea,
.text-input textarea {
	height: 120px;
	resize: vertical;
}


//////////////////////////////////////
//
//  SELECT
//
//////////////////////////////////////

select {
	border-radius: 0;
	-webkit-appearance: none;
	width: 100%;
	&:focus {
		outline: none;
	}
}
select[multiple] {
	padding: $base-spacing-unit / 2;
}

.gfield.styled-select .ginput_container,
.ginput_container.styled-select,
span.styled-select {
	display: block;
	padding-left: $base-spacing-unit / 2;
	width: 100%;
	max-width: 100%;
	height: 35px;
	overflow: hidden;
	background-color: $white;
	color: $base-ui-color;
	background-image: $styled-select-arrow;
	background-position: right 50%;
	background-repeat: no-repeat;
	border: 1px solid $base-ui-color;
	border-radius: $brand-round;

	select {
		background: transparent;
		width: 120%;
		//padding: 0;
		//line-height: 35px;
		line-height: 1;
		padding: 6px 0;
		font-size: $base-font-size;
		height: 35px;
		color: $base-ui-color;
		border: 0;
		border-radius: 0;
		-webkit-appearance: none;
	}
}

.gfield_select,
.gfield_list_cell select {
	position: relative;
	z-index: 2;
	background-size: 16px 16px!important;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAAGa2tLEAAAAJ1BMVEX///9mYmNmYmNmYmNmYmNmYmNmYmNmYmNmYmNmYmNmYmNmYmNmYmMo28KFAAAADHRSTlMAJUVKXGJomKmq5P09lhKdAAAAOUlEQVQI12NgYOBhQAdcDgj2mjMgTgEDVsB05jSQlNkAJJgZCADWzpkzZyaAWFJnzmyBiEltwa8HABccCtQ3+K01AAAAAElFTkSuQmCC) no-repeat 95% 50% #fafafa!important;
}



//////////////////////////////////////
//
//  RADIOS / CHECKBOXES
//
//////////////////////////////////////

.check-list,
ul.gfield_radio,
ul.gfield_checkbox {
	@extend .check-list;
	li, label {
		display: inline-block;
		margin-left: 8px;
		@include primary-font;
	}
	label {
		max-width: 90%;
	}
	li {
		margin-right: 8px;
		margin-bottom: 0;
	}
	li:first-of-type {
		margin-left: 0;
	}
	input[type="checkbox"],
	input[type="radio"] {
		vertical-align: top;
		margin-top: 3px;
		&[value="gf_other_choice"] {
			margin-top: 11px;
		}
	}
	input[value="gf_other_choice"] + input[type="text"] {
		display: inline-block;
		width: 80%;
		margin-left: $base-spacing-unit;
	}
}

.list-vertical {
  ul.gfield_radio,
  ul.gfield_checkbox {
    li {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
  }

}


//////////////////////////////////////
//
//  DATE FIELDS
//
//////////////////////////////////////

.gfield_date_day,
.gfield_date_month,
.gfield_date_year,
.gfield_date_dropdown_day,
.gfield_date_dropdown_month,
.gfield_date_dropdown_year,
.gfield_time_hour,
.gfield_time_minute,
.gfield_time_ampm {
	display: inline-block;
	width: 31%;
	margin-right: 3%;
	label {
		display: inline-block;
		font-size: $base-font-size - 1;
		@include primary-font(normal, italic);
		color: $grey-4;
	}
}
.gfield_date_year,
.gfield_date_dropdown_year,
.gfield_time_ampm {
	margin-right: 0;
}
.gfield_time_hour input {
	display: inline-block;
	max-width: 90%;
}
.gfield_time_ampm {
	display: block;
	float: right;
}

.gf_hide_ampm .gfield_time_ampm {
	display: none !important;
}

.datepicker_with_icon {
  max-width: 90% !important;
  display: inline-block !important;
}
.ui-datepicker-trigger {
  display: inline-block;
  margin-left: $base-spacing-unit;
}

//////////////////////////////////////
//
//  SECTION BREAKS
//
//////////////////////////////////////

.gsection {
	border-top: 1px dashed $base-ui-color;
	padding-top: $base-spacing-unit;
	margin-top: $base-spacing-unit * 2;
}
.gform_title,
.gsection_title {
	@include primary-font;
	font-size: $kilo-size;
	margin-bottom: 0;
	line-height: 1;
}


//////////////////////////////////////
//
//  PAGE BREAKS
//
//////////////////////////////////////

.gf_progressbar_wrapper {
	padding: $base-spacing-unit 0;
	margin-bottom: $base-spacing-unit;
}
.gf_progressbar_title {
	@include primary-font;
	font-size: $h4-size;
	margin-bottom: 10px;
	color: $brand-color;
}

.gf_progressbar {
	background: lighten($brand-color, 60%);
	height: 20px;
	overflow: hidden;
	border: 1px solid $brand-color;
}
.gf_progressbar_percentage {
	background: $brand-color;
	height: $base-spacing-unit;
	margin: 2px;
	span {
		@extend .visuallyhidden;
	}
}

.gform_page_footer {
	@extend .cf;
	border-top: 1px dashed $base-ui-color;
	padding: $base-spacing-unit 0;
	margin: $base-spacing-unit 0;
	.gform_previous_button,
	.gform_next_button {
		@extend .btn;
		line-height: 3;
		float: right;
	}
	.gform_previous_button {
		float: left;
	}
}
.gform_page .gform_page_footer {
	text-align: center;
	.gform_ajax_spinner {
		float: none;
		display: inline-block;
	}
	input[type="submit"] {
		float: right;
	}
}
.gform_page:first-child .gform_page_footer {
	text-align: left;
	.gform_ajax_spinner {
		float: left;
		display: block;
	}
}
.submit-on-left .gform_page .gform_page_footer input[type="submit"] {
	float: left;
}


//////////////////////////////////////
//
//  LIST FIELD
//
//////////////////////////////////////

.gfield_list {
	margin-bottom: 0;
	thead + tbody tr:first-child td {
		padding-top: $base-spacing-unit;
		&.gfield_list_icons {
			padding-top: $base-spacing-unit !important;
		}
	}
	tr {
		border-bottom: 0;
	}
}
.gfield_list td {
	padding-top: 0;
}
.gfield_list_cell {
	padding-left: $base-spacing-unit / 2;
	padding-right: $base-spacing-unit / 2;
	input {
		width: 100%;
	}
	&:first-child {
		padding-left: 0;
	}
}
.gfield_list_icons {
	text-align: right;
	padding-right: 0;
	width: 60px;
	padding-top: 10px !important;
}


//////////////////////////////////////
//
//  SURVEY FIELDS
//
//////////////////////////////////////

.gsurvey-survey-field {

	.gsurvey-rating-wrapper {
		@extend .cf;
	}

	table.gsurvey-likert {
		tbody tr {
			border-bottom: none;
		}
		td.gsurvey-likert-choice {
			&.gsurvey-likert-hover {
				background-color: lighten($brand-color, 30%);
			}
			&.gsurvey-likert-selected {
				background-color: $brand-color;
			}
		}

	}

	.gsurvey-rank {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			margin: 0 0 $base-spacing-unit / 2;
		}
	}
	.gsurvey-rank-choice img {
		position: relative;
		top: 2px;
	}

}



//////////////////////////////////////
//
//  POLL FIELDS
//
//////////////////////////////////////

.gform_footer .gpoll_button,
.gform_wrapper .gpoll_back_button {
	@extend .btn;
	margin-left: 0;
}
.gform_wrapper.gpoll_enabled .gform_footer {
	text-align: center;
	.gform_ajax_spinner {
		float: none;
		display: inline-block;
	}
	input[type="submit"] {
		float: right;
	}
	.gpoll_button {
		float: left;
	}
}
.gform_wrapper.gpoll_enabled.submit-on-left .gform_page .gform_page_footer input[type="submit"] {
	float: left;
	.gpoll_button {
		float: right;
	}
}

.gpoll_enabled .gpoll_summary,
#gforms_confirmation_message .gpoll_container {

	@extend .cf;

	.gpoll_bar_juice {
		box-shadow: none;
		border-radius: 0;

		$color: $brand-color;
		$alt-color: lighten($brand-color, 40%);
		background-color: $brand-color;
		background-image: none;
		/*
		background-image: -webkit-gradient( linear, left bottom, left top, color-stop(0, $color), color-stop(1, $alt-color) );
		background-image: -moz-linear-gradient( center bottom, $color 37%, $alt-color 69% );
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$alt-color}', endColorstr='#{$color}');
		background-image: -ms-linear-gradient(top, $alt-color 0%, $color 100%);
		*/
	}

	.gpoll_ratio_box {
		background: $brand-color;
		border-radius: 0;
	}

	.gpoll_bar_count {
		text-shadow: none;
	}

	.gpoll_back_button {
		margin-top: $base-spacing-unit;
		float: left;
	}

	.gpoll_choice_label.gpoll_value_selected {
		color: $brand-color;
	}

}
#gforms_confirmation_message .gpoll_container {
	margin-bottom: $base-spacing-unit;
}

//////////////////////////////////////
//
//  COMPLEX (eg FULL ADDRESS BLOCK)
//
//////////////////////////////////////

.ginput_complex {
	> span {
		display: block;
		margin-bottom: $base-spacing-unit;
	}
	label {
		font-size: $milli-size;
		color: $grey-4;
		padding-top: 4px;
		@include primary-font(normal, italic);
	}
}


//////////////////////////////////////
//
//  FIELD DESCRIPTION / INSTRUCTION
//
//////////////////////////////////////

.gfield_description,
.gform_fields .instruction {
	color: $placeholder-color;
	font-size: $base-font-size - 1;
	font-style: italic;
	line-height: $base-spacing-unit;
}
.gform_fields .instruction {
	padding-top: 4px;
}


//////////////////////////////////////
//
//  VALIDATION
//
//////////////////////////////////////

.validation_error,
.gfield_description.validation_message,
.gfield_error .gfield_label {
	font-style: normal;
	padding: 0;
	font-size: $base-font-size;
	color: $error-color;
	background: none;
}

.gfield_error {
	input.text-input,
	textarea,
	input[type="text"],
	input[type="password"],
	input[type="name"],
	input[type="email"],
	input[type="url"],
	input[type="tel"],
	input[type="color"],
	input[type="number"],
	&.text-input input,
	&.text-input textarea {
		border-color: $error-color;
		background-color: $white;
		color: $error-color;

		border: 2px solid $error-color;

		&::-webkit-input-placeholder {
			color: $error-color;
		}
	}

	.styled-select {
		border-color: $error-color;
	}
}

.gform_heading,
.validation_error {
	padding-bottom: $base-spacing-unit;
}

.validation_error {
	background: $error-color;
	padding: 1em;
	color: $white;
	margin-bottom: 1.5em;
}

.gfield_description.validation_message {
	display: block;
}
/*
.gfield.credit-card {
	.gfield_description.validation_message {
		display: block;
	}
}
*/


#gforms_confirmation_message {
	margin-bottom: $base-spacing-unit;
}

//////////////////////////////////////
//
//  FOOTER
//
//////////////////////////////////////

.gform_footer {
	@extend .cf;
	//border-top: 1px dashed $base-ui-color;
	padding: $base-spacing-unit 0;
	margin: $base-spacing-unit 0;
	input[type="submit"] {
		float: right;
	}
	.gform_ajax_spinner {
		float: left;
	}
}
.submit-on-left .gform_footer {
	input[type="submit"] {
		float: left;
	}
	.gform_ajax_spinner {
		float: right;
	}
}

.gform_ajax_spinner {
	display: block;
	width: 20px;
	height: 20px;
	margin-top: 10px;
}

//////////////////////////////////////
//
//  SECURITY (eg HONEYPOT) FIELD
//  HIDDEN FIELDS
//
//////////////////////////////////////

.gform_validation_container {
	display: none;
}

.gform_hidden {
	display: none !important;
}



//////////////////////////////////////
//
//  SUBMITS
//
//////////////////////////////////////

form input[type="submit"] {
	@extend .btn;
}
/*
.gform_wrapper input[type="submit"] {
	float: left;
}
*/
.gform_fields input[type="button"] {
	@extend .btn;
}


//////////////////////////////////////
//
//  LAYOUT CLASSES
//
//////////////////////////////////////



.gfield.gf_fourth_one,
.gfield.gf_fourth_two,
.gfield.gf_fourth_three,
.gfield.gf_fourth_four,
.gfield.gf_left_third,
.gfield.gf_middle_third,
.gfield.gf_right_third,
.gfield.gf_left_half,
.gfield.gf_right_half {
	float: left;
}


.gfield.gf_fourth_four,
.gfield.gf_right_third,
.gfield.gf_right_half {
	float: right;
}

.gfield.gf_left_half,
.gfield.gf_right_half {
	width: 50%;
}

.gfield.gf_left_third,
.gfield.gf_middle_third,
.gfield.gf_right_third {
	width: 33.333%;
}

.gfield.gf_fourth_one,
.gfield.gf_fourth_two,
.gfield.gf_fourth_three,
.gfield.gf_fourth_four {
	width: 25%;
}

.gfield.gf_fourth_one,
.gfield.gf_left_third,
.gfield.gf_left_half {
	padding-right: $base-spacing-unit / 2;
}

.gfield.gf_fourth_two {
	padding-right: $base-spacing-unit / 4;
}
.gfield.gf_fourth_three {
	padding-left: $base-spacing-unit / 4;
}

.gfield.gf_fourth_four,
.gfield.gf_right_third,
.gfield.gf_right_half {
	padding-left: $base-spacing-unit / 2;
}


.gfield.gf_fourth_one,
.gfield.gf_fourth_two,
.gfield.gf_fourth_three,
.gfield.gf_fourth_four,
.gfield.gf_middle_third,
.gfield.gf_right_third,
.gfield.gf_right_half {
	clear: none;
}



//////////////////////////////////////
//
//  "DISABLED" FIELDS
//
//////////////////////////////////////
/*
input[type="submit"][disabled="disabled"],
button[type="submit"][disabled="disabled"] {
	opacity: 0.3 !important;
}

.gform_fields input[disabled="disabled"],
.gform_fields input.disabled-input,
.gform_fields .disabled-input input,
.gform_fields .disabled-input select,
.gform_fields .disabled-input .chosen-single,
.gform_fields .disabled-input .chosen-container-single .chosen-single div b,
.gform_fields .disabled-input .chosen-container-single .chosen-single,
.gform_fields .disabled-input .styled-select,
input.disabled-input {
	box-shadow: none !important;
	border-color:transparent !important;
	background-image:none !important;
	background-color:transparent !important;
	pointer-events: none !important;
}
*/

//////////////////////////////////////
//
//  CUSTOM CLASSES
//
//////////////////////////////////////

.gfield.no-label {
	> .gfield_label {
		display: none;
	}
}
gfield.single-option {
	.check-list,
	ul.gfield_radio,
	ul.gfield_checkbox {
		li {
			width: 100%;
		}
	}
}

.gfield.offscreen {
  position: absolute;
  left: -9999px;
  top: -9999px;
}


.ginput_preview .gform_delete {
	cursor: pointer;
}

.gf-image-preview {
	display: block;
	width: 240px;
	margin-bottom: 10px;

	&.round {
		img {
			box-shadow: 0px 0px 0px 5px white;
			border-radius: 50%;
			border: 0px solid white;
			width: 140px;
			height: 140px;
		}
	}
}





@media only screen and (max-width:1024px) {

	.gfield.gf_left_third,
	.gfield.gf_middle_third,
	.gfield.gf_right_third {
		float: left;
		width: 50%;
		padding-left: 0;
		padding-right: 0;
	}
	.gfield.gf_left_third {
		padding-right: $base-spacing-unit / 2;
	}

}

@media only screen and (max-width:850px) {

	.gfield.gf_left_third,
	.gfield.gf_middle_third,
	.gfield.gf_right_third,
	.gfield.gf_left_half,
	.gfield.gf_right_half {
		float: none;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.gfield.gf_fourth_one,
	.gfield.gf_fourth_two,
	.gfield.gf_fourth_three,
	.gfield.gf_fourth_four {
		float: none;
		padding-right: 0;
		padding-left: 0;
		width: 50%;
	}

	.gfield.gf_fourth_one,
	.gfield.gf_fourth_three {
		float: left;
		padding-right: $base-spacing-unit / 4;
	}
	.gfield.gf_fourth_two,
	.gfield.gf_fourth_four {
		float: right;
		padding-left: $base-spacing-unit / 4;
	}

	.gfield.gf_fourth_three {
		clear: both;
	}

	.gfield.gf_fourth_two,
	.gfield.gf_fourth_four {
		padding-right: 0;
	}

}

