/*------------------------------------*\
    $PULL
\*------------------------------------*/
/**
 * Pull classes, to move grid items over to the right by certain amounts.
 */

@mixin pull-setup($namespace: "") {

    /**
     * Whole
     */
    .pull--#{$namespace}one-whole           { right:100%; }


    /**
     * Halves
     */
    .pull--#{$namespace}one-half            { right:50%; }


    /**
     * Thirds
     */
    .pull--#{$namespace}one-third           { right:33.333%; }
    .pull--#{$namespace}two-thirds          { right:66.666%; }


    /**
     * Quarters
     */
    .pull--#{$namespace}one-quarter         { right:25%; }
    .pull--#{$namespace}two-quarters        { @extend .pull--#{$namespace}one-half; }
    .pull--#{$namespace}three-quarters      { right:75%; }


    /**
     * Fifths
     */
    .pull--#{$namespace}one-fifth           { right:20%; }
    .pull--#{$namespace}two-fifths          { right:40%; }
    .pull--#{$namespace}three-fifths        { right:60%; }
    .pull--#{$namespace}four-fifths         { right:80%; }


    /**
     * Sixths
     */
    .pull--#{$namespace}one-sixth           { right:16.666%; }
    .pull--#{$namespace}two-sixths          { @extend .pull--#{$namespace}one-third; }
    .pull--#{$namespace}three-sixths        { @extend .pull--#{$namespace}one-half; }
    .pull--#{$namespace}four-sixths         { @extend .pull--#{$namespace}two-thirds; }
    .pull--#{$namespace}five-sixths         { right:83.333%; }


    /**
     * Eighths
     */
    .pull--#{$namespace}one-eighth          { right:12.5%; }
    .pull--#{$namespace}two-eighths         { @extend .pull--#{$namespace}one-quarter; }
    .pull--#{$namespace}three-eighths       { right:37.5%; }
    .pull--#{$namespace}four-eighths        { @extend .pull--#{$namespace}one-half; }
    .pull--#{$namespace}five-eighths        { right:62.5%; }
    .pull--#{$namespace}six-eighths         { @extend .pull--#{$namespace}three-quarters; }
    .pull--#{$namespace}seven-eighths       { right:87.5%; }


    /**
     * Tenths
     */
    .pull--#{$namespace}one-tenth           { right:10%; }
    .pull--#{$namespace}two-tenths          { @extend .pull--#{$namespace}one-fifth; }
    .pull--#{$namespace}three-tenths        { right:30%; }
    .pull--#{$namespace}four-tenths         { @extend .pull--#{$namespace}two-fifths; }
    .pull--#{$namespace}five-tenths         { @extend .pull--#{$namespace}one-half; }
    .pull--#{$namespace}six-tenths          { @extend .pull--#{$namespace}three-fifths; }
    .pull--#{$namespace}seven-tenths        { right:70%; }
    .pull--#{$namespace}eight-tenths        { @extend .pull--#{$namespace}four-fifths; }
    .pull--#{$namespace}nine-tenths         { right:90%; }


    /**
     * Twelfths
     */
    .pull--#{$namespace}one-twelfth         { right:8.333%; }
    .pull--#{$namespace}two-twelfths        { @extend .pull--#{$namespace}one-sixth; }
    .pull--#{$namespace}three-twelfths      { @extend .pull--#{$namespace}one-quarter; }
    .pull--#{$namespace}four-twelfths       { @extend .pull--#{$namespace}one-third; }
    .pull--#{$namespace}five-twelfths       { right:41.666% }
    .pull--#{$namespace}six-twelfths        { @extend .pull--#{$namespace}one-half; }
    .pull--#{$namespace}seven-twelfths      { right:58.333%; }
    .pull--#{$namespace}eight-twelfths      { @extend .pull--#{$namespace}two-thirds; }
    .pull--#{$namespace}nine-twelfths       { @extend .pull--#{$namespace}three-quarters; }
    .pull--#{$namespace}ten-twelfths        { @extend .pull--#{$namespace}five-sixths; }
    .pull--#{$namespace}eleven-twelfths     { right:91.666%; }
}

@if $pull == true{

/**
 * Not a particularly great selector, but the DRYest way to do things.
 */
[class*="pull--"]{ position:relative; }

@include pull-setup();

@if $palm-pull == true{

    @include media-query(palm){
        @include pull-setup("palm-");
    }

}

@if $lap-pull == true{

    @include media-query(lap){
        @include pull-setup("lap-");
    }

}

@if $lap-and-up-pull == true{

    @include media-query(lap-and-up){
        @include pull-setup("lap-and-up-");
    }

}

@if $portable-pull == true{

    @include media-query(portable){
        @include pull-setup("portable-");
    }

}

@if $desk-pull == true{

    @include media-query(desk){
        @include pull-setup("desk-");
    }

}

}//endif
