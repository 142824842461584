.btn {

	font-size: 15px; line-height: 50px;
	border-radius: 1px;
	padding: 0px 30px;
	margin-bottom: 10px;
	transition: all ease .5s;

	i {
		
		font-size: 20px;
		margin-top: 4px;
		margin-left: 40px;
	}


    &:hover {
        //background: darken($brand-color, 10%);
    }
}

.btn--primary {

	background: transparent;
	color: $primary-colour;
	border: 2px solid $primary-colour;

	&:hover {
		background: darken(white, 10%);
	}
}

.btn--secondary {

	background: transparent;
	color: white;
	border: 2px solid white;
	
	&:hover {
		background: darken($tertiary-colour, 10%);
	}
}

.btn--tertiary {

	&:hover {
		background: none;
	}
}

