@if $use-breadcrumb == true{

/*------------------------------------*\
    $BREADCRUMB
\*------------------------------------*/
/**
 * Simple breadcrumb styling to apply to (ordered) lists. Extends `.nav`, e.g.:
 *
   <ol class="nav  breadcrumb">
       <li><a href=#>Home</a></li>
       <li><a href=#>About</a></li>
       <li><a href=#>The Board</a></li>
       <li class=current><a href=#>Directors</a></li>
   </ol>
 *
 * Demo: jsfiddle.net/inuitcss/rkAY9
 *
 */
.breadcrumb > li + li:before{
    content:"\00BB" "\00A0";
}


/**
 * For denoting a path-like structure, GitHub style, e.g.:
 *
   <ol class="nav  breadcrumb--path">
       <li class=breadcrumb__root><a href=#>inuit.css</a></li>
       <li><a href=#>inuit.css</a></li>
       <li><a href=#>partials</a></li>
       <li class=current><a href=#>objects</a></li>
   </ol>
 *
 */
.breadcrumb--path > li + li:before{
    content:"\002F" "\00A0";
}


/**
 * Assign a delimiter on the fly through a data attribute, e.g.:
 *
   <ol class="nav  breadcrumb">
       <li><a href=#>Home</a></li>
       <li data-breadcrumb="|"><a href=#>About</a></li>
       <li data-breadcrumb="|"><a href=#>The Board</a></li>
       <li data-breadcrumb="|" class=current><a href=#>Directors</a></li>
   </ol>
 *
 */
.breadcrumb > li + li[data-breadcrumb]:before{
    content:attr(data-breadcrumb) "\00A0";
}


/**
 * Denote the root of the tree.
 */
.breadcrumb__root{
    font-weight:bold;
}

}//endif
