/*------------------------------------*\
    $LISTS
\*------------------------------------*/
/**
 * Remove vertical spacing from nested lists.
 */
li{
    > ul,
    > ol{
         margin-bottom:0;
     }
}

/**
 * Have a numbered `ul` without the semantics implied by using an `ol`.
 */
/*ul*/.numbered-list{
    list-style-type:decimal;
}
