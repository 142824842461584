body {
	@include primary-font;
	color: $base-ui-color;
}


a {
	color: $brand-color;
	text-decoration: none;
	transition: color $transition-duration;

	&:hover {
		text-decoration: underline;
	}
}

p,
ul,
ol,
dl,
blockquote,
table,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: $base-spacing-unit;
}

h1, h2, h3, h4, h5, h6 {
	@include primary-font(bold);
	em, i {
		@include primary-font(bold, italic);
	}
}

.bold {
	@extend strong;
	&.italic {
		@extend strong;
		@extend em;
	}
}
.italic {
	@extend em;
	&.bold {
		@extend strong;
		@extend em;
	}
}


.heading-1 {
	font-size: $h1-size;
	line-height: 1.88889;
}
.heading-2 {
	font-size: $h2-size;
	line-height: 1.25;
}
.heading-3 {
	font-size: $h3-size;
	line-height: 1.2;
}
.heading-4 {
	font-size: $h4-size;
	line-height: 1.2;
}
.heading-5 {
	font-size: $h5-size;
	line-height: 1.3;
}
.heading-6 {
	font-size: $h6-size;
	line-height: 1.3;
}


h5,
.heading-5 {
	@include primary-font(medium);
	em, i {
		@include primary-font(medium, italic);
	}
}

h6,
.heading-6 {
	@extend .caps;
}


address {
	font-style: normal;
}



.primary-colour {
	color: $primary-colour;
}
.secondary-colour {
	color: $secondary-colour;
}
.tertiary-colour {
	color: $tertiary-colour;
}

.highlight {
	color: $brand-color;
}
.error {
	color: $error-color;
}


.highlight-bg {
	background: $brand-color;
	color: $brand-reverse-color;
}
