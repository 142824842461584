/*------------------------------------*\
    $PUSH
\*------------------------------------*/
/**
 * Push classes, to move grid items over to the right by certain amounts.
 */

@mixin push-setup($namespace: "") {

    /**
     * Whole
     */
    .push--#{$namespace}one-whole           { left:100%; }


    /**
     * Halves
     */
    .push--#{$namespace}one-half            { left:50%; }


    /**
     * Thirds
     */
    .push--#{$namespace}one-third           { left:33.333%; }
    .push--#{$namespace}two-thirds          { left:66.666%; }


    /**
     * Quarters
     */
    .push--#{$namespace}one-quarter         { left:25%; }
    .push--#{$namespace}two-quarters        { @extend .push--#{$namespace}one-half; }
    .push--#{$namespace}three-quarters      { left:75%; }


    /**
     * Fifths
     */
    .push--#{$namespace}one-fifth           { left:20%; }
    .push--#{$namespace}two-fifths          { left:40%; }
    .push--#{$namespace}three-fifths        { left:60%; }
    .push--#{$namespace}four-fifths         { left:80%; }


    /**
     * Sixths
     */
    .push--#{$namespace}one-sixth           { left:16.666%; }
    .push--#{$namespace}two-sixths          { @extend .push--#{$namespace}one-third; }
    .push--#{$namespace}three-sixths        { @extend .push--#{$namespace}one-half; }
    .push--#{$namespace}four-sixths         { @extend .push--#{$namespace}two-thirds; }
    .push--#{$namespace}five-sixths         { left:83.333%; }


    /**
     * Eighths
     */
    .push--#{$namespace}one-eighth          { left:12.5%; }
    .push--#{$namespace}two-eighths         { @extend .push--#{$namespace}one-quarter; }
    .push--#{$namespace}three-eighths       { left:37.5%; }
    .push--#{$namespace}four-eighths        { @extend .push--#{$namespace}one-half; }
    .push--#{$namespace}five-eighths        { left:62.5%; }
    .push--#{$namespace}six-eighths         { @extend .push--#{$namespace}three-quarters; }
    .push--#{$namespace}seven-eighths       { left:87.5%; }


    /**
     * Tenths
     */
    .push--#{$namespace}one-tenth           { left:10%; }
    .push--#{$namespace}two-tenths          { @extend .push--#{$namespace}one-fifth; }
    .push--#{$namespace}three-tenths        { left:30%; }
    .push--#{$namespace}four-tenths         { @extend .push--#{$namespace}two-fifths; }
    .push--#{$namespace}five-tenths         { @extend .push--#{$namespace}one-half; }
    .push--#{$namespace}six-tenths          { @extend .push--#{$namespace}three-fifths; }
    .push--#{$namespace}seven-tenths        { left:70%; }
    .push--#{$namespace}eight-tenths        { @extend .push--#{$namespace}four-fifths; }
    .push--#{$namespace}nine-tenths         { left:90%; }


    /**
     * Twelfths
     */
    .push--#{$namespace}one-twelfth         { left:8.333%; }
    .push--#{$namespace}two-twelfths        { @extend .push--#{$namespace}one-sixth; }
    .push--#{$namespace}three-twelfths      { @extend .push--#{$namespace}one-quarter; }
    .push--#{$namespace}four-twelfths       { @extend .push--#{$namespace}one-third; }
    .push--#{$namespace}five-twelfths       { left:41.666% }
    .push--#{$namespace}six-twelfths        { @extend .push--#{$namespace}one-half; }
    .push--#{$namespace}seven-twelfths      { left:58.333%; }
    .push--#{$namespace}eight-twelfths      { @extend .push--#{$namespace}two-thirds; }
    .push--#{$namespace}nine-twelfths       { @extend .push--#{$namespace}three-quarters; }
    .push--#{$namespace}ten-twelfths        { @extend .push--#{$namespace}five-sixths; }
    .push--#{$namespace}eleven-twelfths     { left:91.666%; }
}

@if $push == true{

@include push-setup();

/**
 * Not a particularly great selector, but the DRYest way to do things.
 */
[class*="push--"]{ position:relative; }

@if $palm-push == true{

    @include media-query(palm){
        @include push-setup("palm-");
    }

}

@if $lap-push == true{

    @include media-query(lap){
        @include push-setup("lap-");
    }

}

@if $lap-and-up-push == true{

    @include media-query(lap-and-up){
        @include push-setup("lap-and-up-");
    }

}

@if $portable-push == true{

    @include media-query(portable){
        @include push-setup("portable-");
    }

}

@if $desk-push == true{

    @include media-query(desk){
        @include push-setup("desk-");
    }

}

}//endif
