@charset "UTF-8";
/*------------------------------------*\
    STYLE.CSS
\*------------------------------------*/
/**
 *
 * sass --watch style.scss:style.min.css --style compressed
 *
 * Here we pull in some variables, include the inuit.css framework, then add our
 * project-specific components afterwards.
 */


/**
 * Setup
 */
@import "vars";
@import "inuit.css/inuit";





/**
 * She’s all yours, cap’n... Begin importing your stuff here.
 */

@import "mixins";

@import "custom/fonts";
@import "custom/inline-images";

@import "custom/sprites";
@import "custom/buttons";

@import "custom/base";
@import "custom/typography";

@import "custom/fitvids";
//@import "custom/rev-slider";
//@import "custom/iosslider";
@import "custom/fancybox-v3";// beta version support for responsive, touch etc

@import "custom/forms";
@import "custom/forms-datepicker-defaults";
//@import "custom/forms-enhanced-ui";

@import "custom/header";
@import "custom/footer";


@import "custom/chromeframe";

@import "custom/mmenu";
@import "custom/helper";
@import "custom/hamburger";
//@import "custom/flickity";
//@import "custom/flexslider";
@import "custom/owlcarousel";
@import "custom/owltheme";
@import "custom/venobox";
@import "custom/custom";