.grid {
	@extend .cf;
	// to remove whitespace. No need to for HTML comments or floats
	font-size: 0;
	line-height: 0;
}
.grid__item {
	@extend .cf;
	// re-instate default
	font-size: $base-font-size;
	line-height: $base-line-height;
}

.nav,
.nav li {
	margin-bottom: 0;
}


.pull--bottom {
	margin-bottom: 0 !important;
}


.wrap {
	@extend .cf;
	max-width: $site-width;
}


// all images set to be fluid/responsive
img {
	width: 100%;
	max-width: 100%;
	height: auto;
	// add auto class for basic display
	&.auto {
		width: auto;
		max-width: none;
		height: auto;
	}
}


@include media-query(desk){
	@include grid-extras("desk-");
}

@include media-query(lap){
	@include grid-extras("lap-");
}

@include media-query(palm){
	@include grid-extras("palm-");
}
