a.sprite,
a.icon {
	background-color: transparent !important;
	&:hover {
		background-color: transparent !important;
	}
}

.sprite,
.icon {
	top: 0;
	background-repeat: no-repeat;
}


.sprite--close,
.sprite--next,
.sprite--prev {
	width: 19px;
	height: 26px;
	background-size: contain;
}

.sprite--close {
	width: 26px;
	background-image: $icon-close;
}

.sprite--next {
	background-image: $icon-arrow-right;
}

.sprite--prev {
	background-image: $icon-arrow-left;
}
