@if $use-nav == true or $use-options == true or $use-pagination == true or $use-breadcrumb == true{

/*------------------------------------*\
    $NAV
\*------------------------------------*/
/**
 * Nav abstraction as per: csswizardry.com/2011/09/the-nav-abstraction
 * When used on an `ol` or `ul`, this class throws the list into horizontal mode
 * e.g.:
 *
   <ul class=nav>
       <li><a href=#>Home</a></li>
       <li><a href=#>About</a></li>
       <li><a href=#>Portfolio</a></li>
       <li><a href=#>Contact</a></li>
   </ul>
 *
 * Demo: jsfiddle.net/inuitcss/Vnph4
 *
 */
.nav{
    list-style:none;
    margin-left:0;
    @extend .cf;

    > li{

        &,
        > a{
            display:inline-block;
           *display:inline;
            zoom:1;
        }
    }
}


/**
 * `.nav--stacked` extends `.nav` and throws the list into vertical mode, e.g.:
 *
   <ul class="nav  nav--stacked">
       <li><a href=#>Home</a></li>
       <li><a href=#>About</a></li>
       <li><a href=#>Portfolio</a></li>
       <li><a href=#>Contact</a></li>
   </ul>
 *
 */
.nav--stacked{

    > li{
        display:list-item;

        > a{
            display:block;
        }
    }
}


/**
 * `.nav--banner` extends `.nav` and centres the list, e.g.:
 *
   <ul class="nav  nav--banner">
       <li><a href=#>Home</a></li>
       <li><a href=#>About</a></li>
       <li><a href=#>Portfolio</a></li>
       <li><a href=#>Contact</a></li>
   </ul>
 *
 */
.nav--banner{
    text-align:center;
}


/**
 * Give nav links a big, blocky hit area. Extends `.nav`, e.g.:
 *
   <ul class="nav  nav--block">
       <li><a href=#>Home</a></li>
       <li><a href=#>About</a></li>
       <li><a href=#>Portfolio</a></li>
       <li><a href=#>Contact</a></li>
   </ul>
 *
 */
.nav--block{
    line-height:1;
    /**
     * Remove whitespace caused by `inline-block`.
     */
    letter-spacing:-0.31em;
    word-spacing:-0.43em;
    white-space:nowrap;

    > li{
        letter-spacing:normal;
        word-spacing:normal;

        > a{
            padding:$half-spacing-unit;
        }
    }
}


/**
 * Force a nav to occupy 100% of the available width of its parent. Extends
 * `.nav`, e.g.:
 *
   <ul class="nav  nav--fit">
       <li><a href=#>Home</a></li>
       <li><a href=#>About</a></li>
       <li><a href=#>Portfolio</a></li>
       <li><a href=#>Contact</a></li>
   </ul>
 *
 * Thanks to @pimpl for this idea!
 */
.nav--fit{
    display:table;
    width:100%;

    > li{
        display:table-cell;

        > a{
            display:block;
        }
    }
}


/**
 * Make a list of keywords. Extends `.nav`, e.g.:
 *
   `<ul class="nav  nav--keywords>`
 *
 */
.nav--keywords{

    > li{

        &:after{
            content:"\002C" "\00A0";
        }

        &:last-child:after{
            display:none;
        }
    }
}

}//endif
