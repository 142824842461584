/*------------------------------------*\
    $MIXINS
\*------------------------------------*/
/**
 * Create a fully formed type style (sizing and vertical rhythm) by passing in a
 * single value, e.g.:
 *
   `@include font-size(10px);`
 *
 * Thanks to @redclov3r for the `line-height` Sass:
 * twitter.com/redclov3r/status/250301539321798657
 */
@mixin font-size($font-size, $line-height:true){
    font-size:$font-size;
    font-size:($font-size / $base-font-size)*1rem;
    @if $line-height == true{
        line-height:ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
    }
}


/**
 * Style any number of headings in one fell swoop, e.g.:
 *
   .foo{
       @include headings(1, 3){
           color:#BADA55;
       }
    }
 *
 * With thanks to @lar_zzz, @paranoida, @rowanmanning and ultimately
 * @thierrylemoulec for refining and improving my initial mixin.
 */
@mixin headings($from: 1, $to: 6){
    %base-heading {
        @content
    }

    @if $from >= 1 and $to <= 6{
        @for $i from $from through $to{
            h#{$i}{
                @extend %base-heading;
            }
        }
    }
}


/**
 * Create vendor-prefixed CSS in one go, e.g.
 *
   `@include vendor(border-radius, 4px);`
 *
 */
@mixin vendor($property, $value...){
    -webkit-#{$property}:$value;
       -moz-#{$property}:$value;
        -ms-#{$property}:$value;
         -o-#{$property}:$value;
            #{$property}:$value;
}


/**
 * Create CSS keyframe animations for all vendors in one go, e.g.:
 *
   .foo{
       @include vendor(animation, shrink 3s);
   }

   @include keyframe(shrink){
       from{
           font-size:5em;
       }
   }
 *
 * Courtesy of @integralist: twitter.com/integralist/status/260484115315437569
 */
@mixin keyframe ($animation-name){
    @-webkit-keyframes $animation-name{
        @content;
    }

    @-moz-keyframes $animation-name{
        @content;
    }

    @-ms-keyframes $animation-name{
        @content;
    }

    @-o-keyframes $animation-name{
        @content;
    }

    @keyframes $animation-name{
        @content;
    }
}


/**
 * Force overly long spans of text to truncate, e.g.:
 *
   `@include truncate(100%);`
 *
 * Where `$truncation-boundary` is a united measurement.
 */
@mixin truncate($truncation-boundary){
    max-width:$truncation-boundary;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}


/**
 * CSS arrows!!! But... before you read on, you might want to grab a coffee...
 *
 * This mixin creates a CSS arrow on a given element. We can have the arrow
 * appear in one of 12 locations, thus:
 *
 *       01    02    03
 *    +------------------+
 * 12 |                  | 04
 *    |                  |
 * 11 |                  | 05
 *    |                  |
 * 10 |                  | 06
 *    +------------------+
 *       09    08    07
 *
 * You pass this position in along with a desired arrow color and optional
 * border color, for example:
 *
 * `@include arrow(top, left, red)`
 *
 * for just a single, red arrow, or:
 *
 * `@include arrow(bottom, center, red, black)`
 *
 * which will create a red triangle with a black border which sits at the bottom
 * center of the element. Call the mixin thus:
 *
   .foo{
       background-color:#BADA55;
       border:1px solid #ACE;
       @include arrow(top, left, #BADA55, #ACE);
   }
 *
 */
@mixin arrow($arrow-edge, $arrow-location, $arrow-color, $border-color: $arrow-color){

    @if $arrow-edge == top{

        @extend %arrow--top;

        &:before{
            border-bottom-color:$border-color!important;
        }

        &:after{
            border-bottom-color:$arrow-color!important;
        }

        @if $arrow-location == left{
            @extend %arrow--left;
        }

        @if $arrow-location == center{
            @extend %arrow--center;
        }

        @if $arrow-location == right{
            @extend %arrow--right;
        }

    }

    @if $arrow-edge == right{

        @extend %arrow--far;

        &:before{
            border-left-color:$border-color!important;
        }

        &:after{
            border-left-color:$arrow-color!important;
        }

        @if $arrow-location == top{
            @extend %arrow--upper;
        }

        @if $arrow-location == center{
            @extend %arrow--middle;
        }

        @if $arrow-location == bottom{
            @extend %arrow--lower;
        }

    }

    @if $arrow-edge == bottom{

        @extend %arrow--bottom;

        &:before{
            border-top-color:$border-color!important;
        }

        &:after{
            border-top-color:$arrow-color!important;
        }

        @if $arrow-location == left{
            @extend %arrow--left;
        }

        @if $arrow-location == center{
            @extend %arrow--center;
        }

        @if $arrow-location == right{
            @extend %arrow--right;
        }

    }

    @if $arrow-edge == left{

        @extend %arrow--near;

        &:before{
            border-right-color:$border-color!important;
        }

        &:after{
            border-right-color:$arrow-color!important;
        }

        @if $arrow-location == top{
            @extend %arrow--upper;
        }

        @if $arrow-location == center{
            @extend %arrow--middle;
        }

        @if $arrow-location == bottom{
            @extend %arrow--lower;
        }

    }

}


/**
 * Media query mixin.
 *
 * It’s not great practice to define solid breakpoints up-front, preferring to
 * modify your design when it needs it, rather than assuming you’ll want a
 * change at ‘mobile’. However, as inuit.css is required to take a hands off
 * approach to design decisions, this is the closest we can get to baked-in
 * responsiveness. It’s flexible enough to allow you to set your own breakpoints
 * but solid enough to be frameworkified.
 *
 * We define some broad breakpoints in our vars file that are picked up here
 * for use in a simple media query mixin. Our options are:
 *
 * palm
 * lap
 * lap-and-up
 * portable
 * desk
 * desk-wide
 *
 * Not using a media query will, naturally, serve styles to all devices.
 *
 * `@include media-query(palm){ [styles here] }`
 *
 * We work out your end points for you:
 */
$palm-end:              $lap-start - 1px;
$lap-end:               $desk-start - 1px;

@mixin media-query($media-query){

    @if $media-query == palm{

        @media only screen and (max-width:$palm-end) { @content; }

    }

    @if $media-query == lap{

        @media only screen and (min-width:$lap-start) and (max-width:$lap-end) { @content; }

    }

    @if $media-query == lap-and-up{

        @media only screen and (min-width:$lap-start) { @content; }

    }

    @if $media-query == portable{

        @media only screen and (max-width:$lap-end) { @content; }

    }

    @if $media-query == desk{

        @media only screen and (min-width:$desk-start) { @content; }

    }

    @if $media-query == desk-wide{

        @media only screen and (min-width: $desk-wide-start) { @content; }

    }

}
