#footer {
	background-color: #f9f9f9;
	padding: 40px 0 80px;

	.logo {
		display: inline-block;
		vertical-align: top;
		margin-right: 40px;
		margin-bottom: 40px;
		width: 180px;
	}

	.copyright {
		display: inline-block;
		vertical-align: top;

		p {
			font-size: 13px;
		}
	}

}