/*------------------------------------*\
    $FORMS
\*------------------------------------*/
/**
 *
 * Demo: jsfiddle.net/inuitcss/MhHHU
 *
 */
fieldset{
    padding:$base-spacing-unit;
}


/**
 * Text inputs
 *
 * Instead of a `[type]` selector for each kind of form input, we just use a
 * class to target any/every one, e.g.:
   <input type=text class=text-input>
   <input type=email class=text-input>
   <input type=password class=text-input>
 *
 */
.text-input,
textarea{
    /**
     * Style these via your theme stylesheet.
     */
}


/**
 * Group sets of form fields in a list, e.g.:
 *
   <ul class=form-fields>
       <li>
           <label />
           <input />
       </li>
       <li>
           <label />
           <select />
       </li>
       <li>
           <label />
           <input />
       </li>
   </ul>
 *
 */
.form-fields{
    list-style:none;
    margin:0;
}
    .form-fields > li{
        @extend %sass-margin-bottom;
    }
    .form-fields > li:last-child{
        margin-bottom:0;
    }


/**
 * Labels
 *
 * Define a `.label` class as well as a `label` element. This means we can apply
 * label-like styling to meta-labels for groups of options where a `label`
 * element is not suitable, e.g.:
 *
   <li>
       <span class=label>Select an option below:</span>
       <ul class="multi-list  four-cols">
           <li>
               <input /> <label />
           </li>
           <li>
               <input /> <label />
           </li>
           <li>
               <input /> <label />
           </li>
           <li>
               <input /> <label />
           </li>
       </ul>
   </li>
 *
 */
label,
.label{
    display:block;
}
    /**
     * Extra help text in `label`s, e.g.:
     *
       <label>Card number <small class=additional>No spaces</small></label>
     *
     */
    .additional{
        display:block;
        font-weight:normal;
    }


/*
 * Groups of checkboxes and radios, e.g.:
 *
   <li>
       <ul class=check-list>
           <li>
               <input /> <label />
           </li>
           <li>
               <input /> <label />
           </li>
       </ul>
   </li>
 *
 */
.check-list{
    list-style:none;
    margin:0;
}
    /*
     * Labels in check-lists
     */
    .check-label,
    .check-list label,
    .check-list .label{
        display:inline-block;
    }


/**
 * Spoken forms are for forms that read like spoken word, e.g.:
 *
   <li class=spoken-form>
       Hello, my <label for=spoken-name>name</label> is
       <input type=text class=text-input id=spoken-name>. My home
       <label for=country>country</label> is
       <select id=country>
           <option>UK</option>
           <option>US</option>
           <option>Other</option>
       </select>
   </li>
 *
 */
.spoken-form label{
    display:inline-block;
    font:inherit;
}


/**
 * Extra help text displayed after a field when that field is in focus, e.g.:
 *
   <label for=email>Email:</label>
   <input type=email class=text-input id=email>
   <small class=extra-help>.edu emails only</small>
 *
 * We leave the help text in the document flow and merely set it to
 * `visibility:hidden;`. This means that it won’t interfere with anything once
 * it reappears.
 *
 */
/*small*/.extra-help{
    display:inline-block;
    visibility:hidden;
}
.text-input:active + .extra-help,
.text-input:focus + .extra-help{
    visibility:visible;
}
