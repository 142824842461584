@font-face {
  font-family: 'BurlingamePro-Light';
  src: url('fonts/BurlingamePro-Light.eot?#iefix') format('embedded-opentype'),  url('fonts/BurlingamePro-Light.otf')  format('opentype'),
	     url('fonts/BurlingamePro-Light.woff') format('woff'), url('fonts/BurlingamePro-Light.ttf')  format('truetype'), url('fonts/BurlingamePro-Light.svg#BurlingamePro-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}



@mixin normal-font($weight:normal, $style:normal) {
	font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
	font-weight: $weight;
	font-style: $style;
}


@mixin primary-font($weight:normal, $style:normal) {
	font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
	font-style: $style;

	@if ($weight == light) {
		font-weight: 100;
	}
	@else if ($weight == medium) {
		font-weight: 500;
	}
	@else if ($weight == bold) {
		font-weight: 700;
	}
	@else {
		// default (normal)
		font-weight: 300;
	}
}
