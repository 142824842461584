@if $use-media == true{

/*------------------------------------*\
    $MEDIA
\*------------------------------------*/
/**
 * Place any image- and text-like content side-by-side, as per:
 * stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 * E.g.:
 *
   <div class=media>
       <img src=http://placekitten.com/200/300 alt="" class=media__img>
       <p class=media__body>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
       sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
   </div>
 *
 * Demo: jsfiddle.net/inuitcss/cf4Qs
 *
 */
.media{
    display:block;
    @extend .cf;
}
    .media__img{
        float:left;
        margin-right:$base-spacing-unit;
    }
    /**
     * Reversed image location (right instead of left).
     */
    .media__img--rev{
        float:right;
        margin-left:$base-spacing-unit;
    }

        .media__img img,
        .media__img--rev img{
            display:block;
        }

    .media__body{
        overflow:hidden;
    }
        .media__body,
        .media__body > :last-child{
            margin-bottom:0;
        }


    /**
     * `.img`s in `.islet`s need an appropriately sized margin.
     */
    .islet .media__img{
        margin-right:$half-spacing-unit;
    }
    .islet .media__img--rev{
        margin-left:$half-spacing-unit;
    }

}//endif
