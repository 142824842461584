@mixin text-offscreen(){
	text-indent: -9999em;
}

@mixin word-break() {
	-ms-word-break: break-all;
	word-break: break-all;

	/* Non standard for webkit */
	word-break: break-word;

	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

@mixin word-wrap() {
	word-wrap: normal;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	hyphens: none;
}


@mixin grid-extras($namespace: "") {

	.#{$namespace}hide {
		display: none !important;
	}
	.#{$namespace}hard--sides {
		padding-right: 0!important;
		padding-left:  0!important;
	}
	.#{$namespace}soft--sides {
		padding-right: $base-spacing-unit!important;
		padding-left:  $base-spacing-unit!important;
	}
	.#{$namespace}text--left {
		text-align: left !important;
	}
	.#{$namespace}text--right {
		text-align: right !important;
	}
	.#{$namespace}text--center {
		text-align: center !important;
	}
	.#{$namespace}float--left {
		float: left !important;
	}
	.#{$namespace}float--right {
		float: right !important;
	}
	.#{$namespace}float-none {
		float: none !important;
	}
	.#{$namespace}hard--left {
		padding-left: 0 !important;
	}

}


@mixin ease-out-back($prop:transform, $duration:$transition-duration, $delay:0s) {
	transition: $prop $duration $delay cubic-bezier(0.175, 0.885, 0.320, 1.275); /* easeOutBack */
	//transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275); /* easeOutBack */
}
@mixin ease-out-cubic($prop:transform, $duration:$transition-duration, $delay:0s) {
	transition: $prop $duration $delay cubic-bezier(0.215, 0.61, 0.355, 1);
}
