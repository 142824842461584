.chromeframe {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	width: 200px;
	background: $brand-color;
	z-index: 99999;
	padding: 20px;
	color: $brand-reverse-color;
	border: 5px solid $brand-reverse-color;
	font-size: 14px;

	a {
		color: $base-ui-color;
		&:hover {
			color: $brand-reverse-color;
		}
	}
}
