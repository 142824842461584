/*------------------------------------*\
    VARS.SCSS
\*------------------------------------*/
/**
 * Any variables you find set in inuit.css’ `_vars.scss` that you do not wish to
 * keep, simply redefine here. This means that if inuit.css, for example, sets
 * your `$base-font-size` at 16px and you wish it to be 14px, simply redeclare
 * that variable in this file. inuit.css ignores its own variables in favour of
 * using your own, so you can completely modify how inuit.css works without ever
 * having to alter the framework itself.
 */





/*------------------------------------*\
    $OBJECTS-AND-ABSTRACTIONS
\*------------------------------------*/
/**
 * All of inuit.css’ objects and abstractions are initially turned off by
 * default. This means that you start any project with as little as possible,
 * and introducing objects and abstractions is as simple as switching the
 * following variables to `true`.
 */
$use-grids:         true;
$use-flexbox:       false;
$use-columns:       false;
$use-nav:           true;
$use-options:       false;
$use-pagination:    false;
$use-breadcrumb:    true;
$use-media:         true;
$use-marginalia:    false;
$use-island:        false;
$use-block-list:    true;
$use-matrix:        false;
$use-split:         false;
$use-this-or-this:  false;
$use-link-complex:  false;
$use-flyout:        false;
$use-arrows:        false;
$use-sprite:        true;
$use-icon-text:     true;
$use-beautons:      true;
$use-lozenges:      false;
$use-rules:         false;
$use-stats:         false;
$use-greybox:       false;


/*------------------------------------*\
    $OVERRIDES
\*------------------------------------*/
/**
 * Place any variables that should override inuit.css’ defaults here.
 */


$responsive:        true;
$lap-start:         850px;

$base-font-family:  'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
$brand-font: 'BurlingamePro-Light', Arial, Helvetica, sans-serif;


$base-font-size:    15px;
$base-line-height:  30px;

$base-ui-color:     #0a1f3f; //navy

$giga-size:         50px;
$mega-size:         30px;
$kilo-size:         24px;

$h1-size:           30px; // .alpha
$h2-size:           26px; // .beta
$h3-size:           24px; // .gamma
$h4-size:           20px; // .delta
$h5-size:           18px; // .epsilon
$h6-size:           16px; // .zeta

$milli-size:        13px;
$micro-size:        12px;

$brand-color:       #0a1f3f; //navy
$brand-face:        $base-font-family;

$brand-round:        3px;

$base-spacing-unit: 25px;
$border: 1px solid rgba(255,255,255,.4);


/*------------------------------------*\
    $CUSTOM
\*------------------------------------*/
/**
 * Place any of your own variables that sit on top of inuit.css here.
 */

$site-width: 1180px;


$white: #fff;
$black: #000;

$text-grey: $base-ui-color;
$dark: #18191a;


$brand-reverse-color: $white;
$grey-4: #83969f;

$primary-colour: $brand-color;  //navy
$secondary-colour: #ea252c; //red
$tertiary-colour: #0071b2; //light blue


$highlight-color: $brand-color;
$error-color: #f85757;

$transition-duration: 0.3s;
