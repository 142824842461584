@if $use-icon-text == true{

/*------------------------------------*\
    $ICON-TEXT
\*------------------------------------*/
/**
 * For text-links etc that have an icon with them. Sometimes whitespace would
 * suffice in creating a gap between the icon and text, for example:
 *
   <a href=#>
       <i class="s  s--help"></i> Help &amp; support
   </a>
 *
 * However we will sometimes want a larger, explicity set gap:
   <a href=# class=icon-text>
       <i class="icon-text__icon  s  s--help"></i>Help &amp; support
   </a>
 *
 * Demo: jsfiddle.net/inuitcss/Q6Lbf
 *
 */
.icon-text > .icon-text__icon{
    margin-right:$half-spacing-unit / 2;
}


/**
 * We can also reverse the direction of the margin for icons that appear to the
 * right of the text content, thus:
 *
   <a href=# class=icon-text--rev>
       Help &amp; support<i class="icon-text__icon  s  s--help"></i>
   </a>
 *
 */
.icon-text--rev > .icon-text__icon{
    margin-left:$half-spacing-unit / 2;
}

}//endif
