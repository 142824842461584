/*------------------------------------*\
    $DEFAULTS
\*------------------------------------*/
/**
 * inuit.css’ default variables. Redefine these in your `_vars.scss` file (found
 * in the inuit.css-web-template) to override them.
 */





/*------------------------------------*\
    $DEBUG
\*------------------------------------*/
/**
 * Debug mode will visually highlight any potential markup/accessibility quirks
 * in the browser. Set to `true` or `false`.
 */
$debug-mode:        false!default;





/*------------------------------------*\
    $BORDER-BOX
\*------------------------------------*/
/**
 * Do you want all elements to adopt `box-sizing:border-box;` as per
 * paulirish.com/2012/box-sizing-border-box-ftw ?
 */
$global-border-box: true!default;





/*------------------------------------*\
    $BASE
\*------------------------------------*/
/**
 * Base stuff
 */
$base-font-size:    16px!default;
$base-line-height:  24px!default;

/**
 * Base font-family.
 */
$base-font-family:  sans-serif!default;

/**
 * Default colour for objects’ borders etc.
 */
$base-ui-color:     #ccc!default;





/*------------------------------------*\
    $RESPONSIVE
\*------------------------------------*/
/**
 * Responsiveness?
 */
$responsive:        true!default;

/**
 * Responsiveness for widescreen/high resolution desktop monitors and beyond?
 * Note: `$responsive` variable above must be set to true before enabling this.
 */
$responsive-extra:  false!default;

/**
 * Responsive push and pull produce a LOT of code, only turn them on if you
 * definitely need them.
 */
$push:              false!default;
/**
 * Note: `$push` variable above must be set to true before enabling these.
 */
$palm-push:         false!default;
$lap-push:          false!default;
$lap-and-up-push:   false!default;
$portable-push:     false!default;
$desk-push:         false!default;

$pull:              false!default;
/**
 * Note: `$pull` variable above must be set to true before enabling these.
 */
$palm-pull:         false!default;
$lap-pull:          false!default;
$lap-and-up-pull:   false!default;
$portable-pull:     false!default;
$desk-pull:         false!default;

/**
 * Tell inuit.css when breakpoints start.
 */
$lap-start:         481px!default;
$desk-start:        1024px!default;
$desk-wide-start:   1200px!default;





/*------------------------------------*\
    $FONT-SIZES
\*------------------------------------*/
/**
 * Font-sizes (in pixels). Refer to relevant sections for their implementations.
 */
$giga-size:         96px!default;
$mega-size:         72px!default;
$kilo-size:         48px!default;

$h1-size:           36px!default; // .alpha
$h2-size:           30px!default; // .beta
$h3-size:           24px!default; // .gamma
$h4-size:           20px!default; // .delta
$h5-size:           16px!default; // .epsilon
$h6-size:           14px!default; // .zeta

$milli-size:        12px!default;
$micro-size:        10px!default;





/*------------------------------------*\
    $QUOTES
\*------------------------------------*/
/**
 * English quote marks?
 */
$english-quotes:    true!default;

/**
 * If you want English quotes then please do not edit these; they’re only here
 * because Sass needs them.
 */
$open-quote:    null;
$close-quote:   null;

/**
 * If you need non-English quotes, please alter the following values accordingly:
 */
@if $english-quotes != true{
    $open-quote:    \00AB;
    $close-quote:   \00BB;
}





/*------------------------------------*\
    $BRAND
\*------------------------------------*/
/**
 * Brand stuff
 */
$brand-color:       #4a8ec2!default;
$brand-face:        "Helvetica Neue", sans-serif!default;

/**
 * How big would you like round corners to be by default?
 */
$brand-round:        4px!default;





/*------------------------------------*\
    $OBJECTS AND ABSTRACTIONS
\*------------------------------------*/
/**
 * Which objects and abstractions would you like to use?
 */
$use-grids:         false!default;
$use-flexbox:       false!default;
$use-columns:       false!default;
$use-nav:           false!default;
$use-options:       false!default;
$use-pagination:    false!default;
$use-breadcrumb:    false!default;
$use-media:         false!default;
$use-marginalia:    false!default;
$use-island:        false!default;
$use-block-list:    false!default;
$use-matrix:        false!default;
$use-split:         false!default;
$use-this-or-this:  false!default;
$use-link-complex:  false!default;
$use-flyout:        false!default;
$use-arrows:        false!default;
$use-sprite:        false!default;
$use-icon-text:     false!default;
$use-beautons:      false!default;
$use-lozenges:      false!default;
$use-rules:         false!default;
$use-stats:         false!default;
$use-greybox:       false!default;





/*------------------------------------*\
    $FRAMEWORK
\*------------------------------------*/
/**
 * inuit.css will work these next ones out for use within the framework.
 *
 * Assign our `$base-line-height` to a new spacing var for more transparency.
 */
$base-spacing-unit: $base-line-height!default;
$half-spacing-unit: $base-spacing-unit / 2!default;
$line-height-ratio: $base-line-height / $base-font-size;
