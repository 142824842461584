/*----------------------
HEADER
-----------------------*/

#header {

	position: fixed;
	z-index: 999;
	top: 0; left: 0; right: 0;
	background-color: white;
	transition: all ease .5s;
	padding: 0;
	border-bottom: 1px solid rgb(230,230,230);
	height: 80px;

	.logo {
		width: 200px;
		float: left;
		padding-top: 20px;

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
	
}

.header__menu {
	float: right;

	li {
		padding: 0 20px;
		position: relative;

		&:last-child {
			padding-right: 0;
		}

		&.fees {
			margin-left: 4px;
		}
		
	}

	a {
		color: $brand-color;
		font-size: 14px;
		padding: 25px 0;
		border-bottom: 2px solid transparent;

		&:hover {
			color: $secondary-colour;
			border-bottom: 2px solid $secondary-colour;
		}
	}

	li.red a {
		color: $secondary-colour;
	}

	li.fees:before {
			position: absolute;
			content: '';
			height: 28px;
			width: 1px;
			top: 25px; left: -1px;
			background-color: $secondary-colour;
	}

	.current_page_item {
		a {
			color: $secondary-colour;
			border-bottom: 2px solid $secondary-colour;
		}
	}

	@media (max-width: 920px) {
		display: none;
	}
}


